export const faqs = [
    {
        "question": "How does spray foam insulation compare to traditional insulation methods?",
        "answer": "Spray foam provides superior thermal insulation, air sealing, and moisture resistance compared to traditional materials like fiberglass or cellulose."
    },
    {
        "question": "What does spray foam insulation look like?",
        "answer": "Spray foam insulation is a light, foamy material that expands and hardens upon application. It forms a dense, sponge-like structure, providing an effective thermal and air barrier. It's typically a creamy white color but can vary depending on the type."
    },
    {
        "question": "Can spray foam insulation be used outside?",
        "answer": "Yes, spray foam insulation can be used outside, particularly closed-cell foam, which is moisture-resistant and adds structural strength. It's often used in roofing applications and exterior walls for its superior insulative and waterproofing properties."
    },
    {
        "question": "Is spray foam insulation safe?",
        "answer": "When correctly installed and cured, spray foam insulation is safe. It's important that installation is done by professionals and that the area is well-ventilated during and immediately after application to avoid exposure to fumes."
    },
    {
        "question": "What is the lifespan of spray foam insulation?",
        "answer": "Spray foam insulation can last upwards of 20 years, offering a long-term insulation solution for your property."
    },
    {
        "question": "Can spray foam insulation be painted?",
        "answer": "Yes, spray foam insulation can be painted once it's fully cured. It's important to use a paint compatible with foam materials. Consulting with a professional is recommended for the best results."
    },
    {
        "question": "What dissolves spray foam insulation?",
        "answer": "Acetone or a similar solvent can dissolve uncured spray foam. Once cured, it becomes rigid and difficult to remove, often requiring mechanical methods such as cutting or scraping for removal."
    },
    {
        "question": "Is spray foam insulation good?",
        "answer": "Spray foam insulation is highly effective at sealing air leaks, providing excellent thermal insulation, and improving energy efficiency. It can contribute to a more comfortable and cost-effective home environment."
    },
    {
        "question": "Can spray foam insulation be applied by yourself?",
        "answer": "DIY application of spray foam insulation is not recommended due to the need for specialized equipment, safety considerations, and technical expertise. Professional installation ensures optimal performance, safety, and compliance with building codes."
    },
    {
        "question": "Who invented spray foam insulation?",
        "answer": "Spray foam insulation was developed in the 1940s by Otto Bayer and his team. Originally designed for aircraft, its use has expanded into various residential and commercial applications due to its excellent insulation properties."
    },
    {
        "question": "What does spray foam insulation cost?",
        "answer": "The cost of spray foam insulation varies based on factors like project size, type of foam (open-cell vs. closed-cell), and location. It's generally more expensive than traditional insulation materials but offers superior energy efficiency and long-term savings."
    },
    {
        "question": "Can spray foam insulation improve indoor air quality?",
        "answer": "Absolutely. By creating an air-tight seal, it minimizes the entry of pollutants and allergens, enhancing indoor air quality."
    },
    {
        "question": "Which spray foam insulation is best?",
        "answer": "The best type of spray foam insulation depends on your specific requirements. Closed-cell foam offers higher R-value, moisture resistance, and structural support, while open-cell foam is more affordable, provides soundproofing benefits, and is easier to apply in hard-to-reach areas."
    },
    {
        "question": "Is spray foam insulation environmentally friendly?",
        "answer": "Yes, our spray foam products are designed to be eco-friendly, reducing energy consumption and utilizing sustainable materials."
    },
    {
        "question": "What is spray foam insulation made of?",
        "answer": "Spray foam insulation is made from two primary chemicals: isocyanate and polyol resin. When mixed, they react to create polyurethane foam, which expands to fill gaps and hardens to form an insulating layer."
    },
    {
        "question": "Where to buy spray foam insulation?",
        "answer": "Spray foam insulation can be purchased from home improvement stores or specialized insulation suppliers. We recommend consulting with a professional installer to determine the best product for your specific needs."
    },
    {
        "question": "Can spray foam insulation get wet?",
        "answer": "Closed-cell spray foam insulation is water-resistant and can handle occasional moisture without losing its insulative properties. Open-cell foam, however, is more porous and can absorb water, so it's not recommended for areas prone to moisture."
    },
    {
        "question": "Can spray foam insulation be left exposed?",
        "answer": "Building codes usually require spray foam insulation to be covered with a thermal barrier like drywall for safety reasons, especially in living spaces. However, it can be left exposed in non-living spaces like unfinished basements or attics, depending on local codes."
    },
    {
        "question": "Will spray foam insulation stick to metal?",
        "answer": "Yes, spray foam insulation adheres well to metal surfaces, making it an excellent choice for metal buildings or structures. It forms a strong bond that provides effective insulation and air sealing."
    },
    {
        "question": "Is spray foam insulation flammable?",
        "answer": "Spray foam insulation is generally treated with fire retardants to reduce flammability. However, like many building materials, it can burn under certain conditions. It's essential to cover it with a fire-rated barrier like drywall according to local building codes."
    },
    {
        "question": "Is spray foam insulation bad?",
        "answer": "While spray foam insulation offers many benefits, concerns include its higher cost compared to traditional insulation, potential for incorrect installation, and the need for proper ventilation during application due to fumes. Choosing a reputable installer can mitigate these issues."
    },
    {
        "question": "Is spray foam insulation worth it?",
        "answer": "Spray foam insulation can be a worthwhile investment for many homeowners, offering significant energy savings, improved indoor air quality, and long-term durability. It may have a higher upfront cost but can pay off in energy savings over time."
    }
]