import React from "react";
import {SiteData} from "../../Constants/siteData";
import {PageWrapper} from "../../Components/PageWrapper/PageWrapper";
import PageHeader from "../../Components/common/PageHeader";
import ServicePageTemplate from "../../Containers/ServiceContainers/ServicePageTemplate";
import {SEO} from "../../Components/SEO/SEO";

export default function InsulationRemovalAndReplacement() {
    return (
        <PageWrapper>
            <SEO description={SiteData.insulationRemovalAndReplacement.metaData.description}
                 title={SiteData.insulationRemovalAndReplacement.metaData.title}/>
            <PageHeader header={SiteData.insulationRemovalAndReplacement.h1}
                        subtitle={SiteData.insulationRemovalAndReplacement.subtitle}/>
            <ServicePageTemplate body={SiteData.insulationRemovalAndReplacement.body}/>
        </PageWrapper>
    )
}
