import React from "react";
import { Heading, Link, List, ListItem, Stack, Text } from "@chakra-ui/react";
import { SiteData } from "./siteData";

export const BlogPost2 = () => {
  return (
    <Stack spacing={3}>
      <Text>
        Saving money and helping the planet starts with making your house more energy-smart. Insulating your home the
        right way means less money spent on heating and cooling and doing a favor for the environment too.
      </Text>
      <Heading as="h3">
        Why is Spray Foam Insulation a Smart Choice?
      </Heading>
      <Text>
        Let's talk about why spray foam insulation is a winner for keeping homes cozy and energy bills low:
      </Text>
      <List styleType="disc">
        <ListItem>
          <strong>Awesome at Blocking Air:</strong> Spray foam insulation acts like a superhero cape for your house,
          filling up cracks and gaps super fast. This keeps your home’s warm or cool air exactly where it should be,
          inside. It’s a quick fix for making sure your home in Westminster, Colorado, stays snug and energy use stays
          low.
        </ListItem>
        <ListItem>
          <strong>Really Good at Stopping Heat:</strong> The R-value is like a score for how well insulation keeps heat
          from escaping. Our spray foam is top of the class, keeping your home warmer or cooler better than anything
          else out there. It’s the best choice for making sure your Westminster home is comfy year-round.
        </ListItem>
        <ListItem>
          <strong>Keeps Water Out:</strong> Water from leaks or floods can cause big problems, like mold, which isn’t
          good for you or your house. Our insulation creates a barrier that water can’t get through, keeping your home
          dry and safe.
        </ListItem>
        <ListItem>
          <strong>Goes the Distance:</strong> Picking our team for your spray foam insulation means your house gets
          protected for a long time. This isn’t just good for saving energy; it also makes your house more valuable if
          you ever decide to sell.
        </ListItem>
      </List>
      <Text>
        Our friendly experts are here to help you figure out the best insulation for your home and give you a free
        quote. If you’re ready to make your home more energy-efficient and comfortable, give Spray Foam Insulation
        Westminster a call at <Link href={SiteData.telLink}>720-619-8255</Link>.
      </Text>
    </Stack>
  );
};
