import React from "react";
import {Box, Text, Heading, ListItem, Stack, UnorderedList} from "@chakra-ui/react";

export const RemovalAndReplacement = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Insulation Removal & Replacement</Heading>
                <Text>At Spray Foam Insulation Westminster, we provide comprehensive insulation removal and replacement
                    services for both residential and commercial properties in Westminster, Colorado.</Text>
                <UnorderedList spacing={3}>
                    <ListItem><b>Expert Technicians:</b> Our team has the experience and knowledge to handle insulation
                        removal and replacement efficiently and safely.</ListItem>
                    <ListItem><b>Quality Materials:</b> We use the highest quality spray foam insulation to ensure the
                        best thermal performance for your property.</ListItem>
                    <ListItem><b>Customer Satisfaction:</b> Your satisfaction is our highest priority. We provide
                        transparent pricing, timely services, and an unmatched commitment to quality.</ListItem>
                    <ListItem><b>Eco-friendly Approach:</b> We're committed to using materials and practices that are
                        environmentally friendly and sustainable.</ListItem>
                </UnorderedList>
            </Stack>
        </Box>
    )
}