import React from "react";
import {
  Box,
  Text,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
} from "@chakra-ui/react";

export const CommercialInsulation = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Commercial Insulation Services</Heading>
        <Text>
          Spray Foam Insulation Westminster extends its top-tier services to the
          commercial sector in Westminster, Colorado. Our dedicated team ensures
          professional and efficient installations, minimizing disruption to
          your business operations. Whether it's insulation, roofing, or other
          services, trust us to deliver solutions that meet the unique needs of
          commercial properties, prioritizing effectiveness and minimal impact
          on your daily business activities.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Expert Installation:</b> At Spray Foam Insulation Westminster,
            our trained technicians prioritize proper installation to ensure
            maximum insulation benefits. With expertise and attention to detail,
            our team guarantees that insulation solutions are applied
            effectively, optimizing energy efficiency and comfort for both
            residential and commercial properties in Westminster, Colorado.
            Trust us for professional installations that deliver lasting
            insulation benefits, providing a comfortable and energy-efficient
            environment for your space.
          </ListItem>
          <ListItem>
            <b>Energy Savings:</b> Choose Spray Foam Insulation Westminster for
            insulation solutions that effectively reduce energy consumption and
            lower utility bills. Our spray foam insulation creates a thermal
            barrier, minimizing heat transfer and improving energy efficiency
            for both residential and commercial properties in Westminster,
            Colorado. Trust us to deliver insulation that not only enhances
            comfort but also contributes to significant long-term cost savings.
          </ListItem>
          <ListItem>
            <b>Improved Comfort:</b> Investing in better insulation from Spray
            Foam Insulation Westminster translates to a more comfortable
            environment for both employees and customers. Our insulation
            solutions, whether for residential or commercial properties in
            Westminster, Colorado, contribute to maintaining consistent
            temperatures and a pleasant atmosphere. Trust us to deliver
            insulation that not only enhances energy efficiency but also
            prioritizes the comfort and well-being of the occupants in your
            space.
          </ListItem>
          <ListItem>
            <b>Eco-friendly:</b> At Spray Foam Insulation Westminster, our
            insulation products are environmentally safe, aligning with your
            company's sustainability goals. We prioritize eco-friendly solutions
            that contribute to a greener and more sustainable future. By
            choosing our insulation products for your residential or commercial
            property in Westminster, Colorado, you not only enhance energy
            efficiency but also demonstrate a commitment to environmental
            responsibility and corporate sustainability.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  );
};
