import {createBrowserRouter} from 'react-router-dom';
import HomeLayout from '../Layouts/HomeLayout';
import About from '../Pages/About';
import ContactPage from '../Pages/ContactPage';
import Home01 from '../Pages/Homes/Home01';
import Services01 from '../Pages/Services/Services01';
import ErrorPage from '../Pages/ErrorPage/ErrorPage';
import Blog from "../Pages/Blog";
import SprayFoamRoofing from "../Pages/Services/SprayFoamRoofing";
import CommercialInsulation from "../Pages/Services/CommercialInsulation";
import SoundProofing from "../Pages/Services/SoundProofing";
import InsulationRemovalAndReplacement from "../Pages/Services/InsulationRemovalAndReplacement";
import Careers from "../Pages/Careers";
import React from "react";
import {BlogPost} from "../Layouts/BlogPost";
import CrawlSpaceInsulation from "../Pages/Services/CrawlSpaceInsulation";
import ResidentialInsulation from "../Pages/Services/ResidentialInsulation";
import EnergyAudits from "../Pages/Services/EnergyAudits";
import DirectoriesOnTheWeb from "../Pages/DirectoriesOnTheWeb";
import AtticInsulation from "../Pages/Services/AtticInsulation";
import CrawlSpaceEncapsulation from "../Pages/Services/CrawlSpaceEncapsulation";
import CrawlSpaceVaporBarriers from "../Pages/Services/CrawlSpaceVaporBarriers";
import BattInsulation from "../Pages/Services/BattInsulation";
import BlowInInsulation from "../Pages/Services/BlowInInsulation";
import AtticFans from "../Pages/Services/AtticFans";
import WholeHouseFans from "../Pages/Services/WholeHouseFans";


export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>
            },
            {
                path: '/blog',
                element: <Blog/>
            },
            {
                path: '/services',
                element: <Services01/>
            },
            {
                path: '/insulation-removal-replacement',
                element: <InsulationRemovalAndReplacement/>
            },
            {
                path: '/commercial-insulation',
                element: <CommercialInsulation/>
            },
            {
                path: '/residential-insulation',
                element: <ResidentialInsulation/>
            },
            {
                path: '/spray-foam-roofing',
                element: <SprayFoamRoofing/>
            },
            {
                path: '/soundproofing',
                element: <SoundProofing/>
            },
            {
                path: '/energy-audits',
                element: <EnergyAudits/>
            },
            {
                path: '/crawl-space-insulation',
                element: <CrawlSpaceInsulation/>
            },
            {
                path: '/attic-insulation',
                element: <AtticInsulation/>
            },
            // {
            //     path: '/whole-house-fans',
            //     element: <WholeHouseFans/>
            // },
            {
                path: '/attic-fans',
                element: <AtticFans/>
            },
            {
                path: '/blow-in-insulation',
                element: <BlowInInsulation/>
            },
            {
                path: '/batt-insulation',
                element: <BattInsulation/>
            },
            {
                path: '/crawl-space-vapor-barriers',
                element: <CrawlSpaceVaporBarriers/>
            },
            {
                path: '/crawl-space-encapsulation',
                element: <CrawlSpaceEncapsulation/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/careers',
                element: <Careers/>
            },
            {
                path: '/contact',
                element: <ContactPage/>
            },
            {
                path: '/blog/:id',
                element: <BlogPost/>
            },
            {
                path: '/directories-on-the-web',
                element: <DirectoriesOnTheWeb/>
            },
            {
                path: '*',
                element: <ErrorPage/>
            },
        ]
    }
])

