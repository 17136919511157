import React from "react";
import {
  Box,
  Text,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
} from "@chakra-ui/react";

export const SoundProofing = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Soundproofing Services</Heading>
        <Text>
          At Spray Foam Insulation Westminster, we offer premier soundproofing
          services for both residential and commercial spaces in Westminster,
          Colorado. Elevate your living or working experience with our top-notch
          soundproofing solutions, designed to provide tranquility and privacy.
          Our expert team is committed to delivering effective soundproofing
          solutions that cater to the unique needs of your space, ensuring a
          peaceful environment for residents and optimal conditions for
          businesses. Experience the difference with our dedicated soundproofing
          services.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Effective Noise Reduction:</b> At Spray Foam Insulation
            Westminster, we use premium soundproofing materials to minimize
            noise in residential and commercial spaces, ensuring tranquility and
            privacy. Our solutions provide effective relief from disturbances,
            creating a more comfortable environment in Westminster, Colorado.
          </ListItem>
          <ListItem>
            <b>Skilled Technicians:</b> Rely on our experienced team at Spray
            Foam Insulation Westminster for professional and thorough
            soundproofing installations, ensuring maximum effectiveness. With
            meticulous attention to detail, we tailor our installations to
            enhance soundproofing in your residential or commercial space in
            Westminster, Colorado. Trust our team to deliver a high standard of
            service, providing optimal tranquility and privacy.
          </ListItem>
          <ListItem>
            <b>Comfort & Privacy:</b> Spray Foam Insulation Westminster's
            soundproofing services elevate the comfort and privacy of your
            space, crafting a more serene environment. Our tailored solutions
            are designed to minimize disturbances, ensuring a tranquil
            atmosphere in residential or commercial settings. Trust us to
            enhance your living or working experience through effective
            soundproofing measures that contribute to a more peaceful and
            private environment in Westminster, Colorado.
          </ListItem>
          <ListItem>
            <b>Value Addition:</b> Enhance your property's value by investing in
            effective soundproofing, a sought-after feature in modern homes and
            offices. Our soundproofing solutions at Spray Foam Insulation
            Westminster not only contribute to a quieter and more comfortable
            living or working environment but also add significant appeal and
            value to your property. Elevate the market value of your space with
            our soundproofing services, meeting the demands of contemporary
            preferences for tranquility and privacy in Westminster, Colorado.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  );
};
