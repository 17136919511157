import React from "react";
import { Heading, Link, List, ListItem, Stack, Text } from "@chakra-ui/react";
import { SiteData } from "./siteData";

export const BlogPost3 = () => {
  return (
    <Stack spacing={3}>
      <Text>
        Just like you wouldn’t pick a doctor without the right schooling, don’t hand over your home’s comfort to someone
        who isn’t a pro in spray foam insulation. Only someone with the right skills can make sure your home is safe
        from the chemicals used in spray foam and prevent big problems before they start.
      </Text>
      <Heading as="h3">
        The Importance of Choosing a Pro for Spray Foam Insulation
      </Heading>
      <Text>
        Hiring a trusted contractor might cost a bit more now, but it means avoiding the expense of fixing mistakes
        later. Think about why a certified expert is your best bet:
      </Text>
      <List styleType="disc">
        <ListItem>
          <strong>Safe From Chemicals:</strong> Spray foam has chemicals that could be risky without the right know-how.
          Our team at Spray Foam Insulation Westminster is trained to keep everyone safe while making your home cozy and
          energy-smart. Rely on us for a job that’s done safely and well.
        </ListItem>
        <ListItem>
          <strong>Guaranteed Work:</strong> It’s vital to pick someone who gives you a solid contract and a promise to
          fix anything that doesn’t meet your standards. We’re all about clear deals and making sure you’re happy with
          the work in your Westminster home.
        </ListItem>
        <ListItem>
          <strong>Efficient and Saves Money:</strong> A skilled expert means insulation is applied perfectly everywhere,
          stopping heat from sneaking out. Our precise work at Spray Foam Insulation Westminster means your home is
          wrapped up tight, saving you energy and money.
        </ListItem>
        <ListItem>
          <strong>Reliable Service:</strong> Going with a well-trained local for your insulation means getting someone
          who knows what your home needs. Our local crew at Spray Foam Insulation Westminster is committed to top-notch
          service and understanding what works best for our neighbors.
        </ListItem>
      </List>
      <Text>
        Thinking about spray foam insulation? Get in touch with us at Spray Foam Insulation Westminster for a free
        consultation and quote from a team that’s ready to handle any insulation project you’ve got. Give us a call
        at <Link href={SiteData.telLink}>720-619-8255</Link> for service that makes you and your home comfy.
      </Text>
    </Stack>
  );
};
