import React from "react";
import {
  Box,
  Text,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
} from "@chakra-ui/react";

export const ResidentialInsulation = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Residential Insulation Services</Heading>
        <Text>
          Spray Foam Insulation Westminster offers superior residential
          insulation services for homeowners in Westminster, Colorado. Our
          high-quality spray foam insulation is designed to enhance the comfort
          of your home, reduce energy costs, and improve overall energy
          efficiency. Trust our experienced team to deliver insulation solutions
          that prioritize the well-being of your home and contribute to
          long-term cost savings.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Professional Installation:</b> Count on our team at Spray Foam
            Insulation Westminster to ensure a proper and safe installation of
            your home insulation. With a commitment to precision and safety, we
            guarantee that our insulation solutions are applied effectively,
            providing optimal energy efficiency and comfort for homeowners in
            Westminster, Colorado. Trust us for a professional and secure
            installation that enhances the insulation performance of your home,
            creating a more comfortable and energy-efficient living environment.
          </ListItem>
          <ListItem>
            <b>Energy Efficiency:</b> Experience significant reduction in your
            home's heating and cooling costs with our spray foam insulation at
            Spray Foam Insulation Westminster. Our high-quality insulation
            solutions create an effective thermal barrier, minimizing heat
            transfer and optimizing energy efficiency for homeowners in
            Westminster, Colorado. Trust us to deliver insulation that not only
            enhances comfort but also contributes to substantial long-term
            savings on your home's heating and cooling expenses.
          </ListItem>
          <ListItem>
            <b>Improved Home Comfort:</b> Ensure your home remains consistently
            comfortable with our well-insulated solutions at Spray Foam
            Insulation Westminster. Our insulation services create an effective
            thermal barrier, maintaining a steady temperature and enhancing
            overall comfort for homeowners in Westminster, Colorado. Trust us to
            deliver insulation that not only optimizes energy efficiency but
            also prioritizes a comfortable living environment for you and your
            family.
          </ListItem>
          <ListItem>
            <b>Environmentally Friendly:</b> At Spray Foam Insulation
            Westminster, we prioritize sustainability by using eco-friendly
            materials that minimize your home's carbon footprint. Our insulation
            solutions are designed to not only enhance energy efficiency but
            also contribute to a greener and more environmentally responsible
            living space for homeowners in Westminster, Colorado. Trust us for
            insulation that aligns with your commitment to sustainability,
            creating a home that is both comfortable and eco-friendly.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  );
};
