export const posts = [
    {
        id: '1',
        title: 'The Benefits of Spray Foam Insulation in Westminster Homes',
        excerpt: 'Being that the best insulation remains hidden, you may not yet have considered the different options you have for insulating your home. We wrote this blog post to educate you about why spray foam insulation is the best choice to make your house into a home.',
        slug: 'benefits-of-spray-foam-insulation',
        tags: ['spray foam insulation', 'energy efficiency', 'home comfort'],
        metaDescription: 'Uncover the benefits of choosing spray foam insulation for your Westminster home. Improve energy efficiency, enhance comfort, and save on utility bills.',
        lastModified: new Date('2023-12-03').toISOString(),
        createdAt: new Date('2023-12-03').toISOString()
    },
    {
        id: '2',
        title: 'How Spray Foam Insulation Contributes to Energy Efficiency',
        excerpt: 'Did you know that improving your energy efficiency can save you hundreds of dollars in utility or repair fees? By ensuring your home is properly insulated, you are minimizing the cost to your wallet and the environment.',
        slug: 'spray-foam-and-energy-efficiency',
        tags: ['energy efficiency', 'spray foam insulation', 'save energy'],
        metaDescription: 'Learn how spray foam insulation enhances energy efficiency, resulting in significant energy savings and reduced utility bills.',
        lastModified: new Date('2023-12-15').toISOString(),
        createdAt: new Date('2023-12-15').toISOString()
    },
    {
        id: '3',
        title: 'Why Choose a Professional for Your Spray Foam Insulation Needs',
        excerpt: 'Just like you would never trust a doctor that didn’t go to medical school, you should never entrust the integrity of your home to someone who is not properly qualified in spray foam insulation and installation. Only a qualified professional is capable of minimizing the risk of harmful chemicals and addressing serious problems before they happen.',
        slug: 'choose-a-professional-for-spray-foam',
        tags: ['spray foam insulation', 'professional installation'],
        metaDescription: 'Discover why it’s essential to choose a professional for your spray foam insulation project. Ensure a safe, efficient, and effective insulation installation.',
        lastModified: new Date('2023-12-20').toISOString(),
        createdAt: new Date('2023-12-20').toISOString()
    },
    {
        id: '4',
        title: 'Spray Foam Insulation: Open-Cell vs. Closed-Cell',
        excerpt: 'A key indicator that you are working with a professional and reputable spray foam insulation service provider is their ability to properly explain the difference between open-cell and closed-cell foam insulation. Spray Foam Insulation Westminster maintains its reputation as the top service provider in the area because we make you a part of this process, which otherwise, can be confusing. Please read on about the unique characteristics and uses of open-cell and closed-cell foam.',
        slug: 'open-cell-vs-closed-cell-spray-foam',
        tags: ['spray foam insulation', 'open-cell', 'closed-cell'],
        metaDescription: 'Compare open-cell and closed-cell spray foam insulation. Learn their differences and which type suits your home’s insulation needs best.',
        lastModified: new Date('2024-1-04').toISOString(),
        createdAt: new Date('2024-1-04').toISOString()
    },
]

