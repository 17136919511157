import React from "react";
import {
  Box,
  Text,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
} from "@chakra-ui/react";

export const EnergyAudits = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Energy Audit Services</Heading>
        <Text>
          Enhance your property's energy efficiency with our comprehensive
          energy audit services at Spray Foam Insulation Westminster. We
          specialize in assisting residents and businesses in Westminster,
          Colorado, by providing insights into and improving their energy
          consumption. Our dedicated team conducts thorough energy audits to
          identify areas for improvement, implementing solutions that contribute
          to a more sustainable and cost-effective energy profile for your
          property. Trust us to guide you in optimizing energy efficiency,
          reducing costs, and promoting environmental responsibility in
          Westminster.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Thorough Evaluation:</b> Our process involves conducting a
            thorough evaluation of your property to pinpoint areas of energy
            inefficiency. Through detailed assessments, we identify specific
            areas that may benefit from improvements to enhance overall energy
            performance. This comprehensive evaluation forms the foundation for
            our tailored recommendations and solutions aimed at optimizing your
            property's energy efficiency.
          </ListItem>
          <ListItem>
            <b>Experienced Auditors:</b> Benefit from our skilled team with
            years of experience in conducting precise and helpful energy audits.
            Our expertise ensures a thorough examination of your property,
            identifying potential areas of energy inefficiency and providing
            insightful recommendations. With a commitment to excellence, we
            bring a wealth of knowledge to the table, enabling us to offer
            tailored solutions that enhance your property's overall energy
            performance. Trust our experienced team to guide you towards a more
            energy-efficient and sustainable future.
          </ListItem>
          <ListItem>
            <b>Energy Savings:</b> Our meticulous energy audits uncover
            opportunities to reduce energy usage, translating into substantial
            long-term cost savings. By identifying and addressing
            inefficiencies, we provide tailored solutions that enhance your
            property's overall energy performance. With a focus on both
            environmental sustainability and financial benefits, our
            recommendations empower you to make informed decisions. Trust us to
            guide you towards a more energy-efficient and cost-effective future.
          </ListItem>
          <ListItem>
            <b>Eco-friendly:</b> Through the optimization of energy consumption,
            our audits play a crucial role in reducing your property's
            environmental impact. By identifying and implementing
            energy-efficient measures, we aim to minimize the carbon footprint
            associated with your property, fostering a more sustainable and
            eco-friendly approach to energy usage. Trust our commitment to
            environmental responsibility as we work towards creating a greener
            future for your property.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  );
};
