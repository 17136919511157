import React from 'react';
import PageHeader from '../Components/common/PageHeader';
import Map from '../Components/ContactElements/Map';
import Banner from '../Containers/Banner';
import {SiteData} from "../Constants/siteData";
import ContactFormSection from "../Components/ContactFormSection/ContactFormSection";
import {SEO} from "../Components/SEO/SEO";
import {Box, Container, Heading, Link, Text} from "@chakra-ui/react";

const ContactPage = () => {
    return (
        <>
            <SEO description={SiteData.contact.metaData.description} title={SiteData.contact.metaData.title}/>
            <PageHeader header={SiteData.contact.h1} subtitle={SiteData.contact.subtitle}/>
            <div className="main_wrapper">
                <Container maxW='container.xl' centerContent>
                    <Box
                        p={6}
                        borderRadius='lg'
                        m={4}
                        textAlign='left'
                    >
                        <Heading as='h2' size='xl' mb={6}>
                            Contact Westminster's Premier Spray Foam Insulation Experts
                        </Heading>

                        <Text fontSize='lg' mb={4}>
                            Welcome to Spray Foam Insulation Westminster, where your comfort and energy
                            efficiency is our top priority. We're thrilled you're considering us for your insulation
                            needs. Whether you're looking to reduce energy costs, soundproof your space, or enhance the
                            overall comfort of your home or business, our team of skilled professionals is here to guide
                            you every step of the way.
                        </Text>

                        <Text fontSize='lg' mb={4}>
                            At Spray Foam Insulation Westminster, and across the Denver metro area, we understand that
                            each project is unique. That's why we offer personalized consultations to assess your
                            specific needs. Our <Link href={'/services/'}>services</Link> range from residential to
                            commercial insulation, and we specialize in high-quality, eco-friendly spray foam solutions
                            that ensure the best results for any project.
                        </Text>

                        <Text fontSize='lg' mb={4}>
                            We pride ourselves on our commitment to customer satisfaction and our unparalleled expertise
                            in the field of insulation. Our team stays updated with the latest technologies and
                            insulation methods to provide you with the most efficient and cost-effective solutions.
                        </Text>

                        <Heading as='h2' size='lg' mb={4}>
                            Get in Touch
                        </Heading>

                        <Text fontSize='lg' mb={4}>
                            Have questions or need a quote? Fill out the form below, and a member of our team will get
                            back to you promptly. We're here to answer your questions and provide detailed information
                            about our services and how we can assist in making your space more energy-efficient and
                            comfortable.
                        </Text>

                        <Text fontSize='lg'>
                            Prefer to speak with us directly? Feel free to call us at <Link color='teal.500'
                                                                                            href={SiteData.telLink}>{SiteData.phoneNumber}</Link>.
                            We're open Monday
                            to Saturday, 7:00 AM to 6:00 PM.
                        </Text>

                        <Text fontSize='lg' mt={6}>
                            Thank you for considering Spray Foam Insulation Westminster. We look forward to helping you
                            achieve the comfort and efficiency your space deserves!
                        </Text>
                    </Box>
                </Container>
                <ContactFormSection data={[{
                    id: '1',
                    iconClass: 'ion-ios-telephone-outline',
                    infoBody01: 'Call us Today!',
                    infoBody02: SiteData.phoneNumber,
                    link: SiteData.telLink,
                    heading: 'Get a FREE estimate',
                    cta: 'Provide some information and get a FREE quote today'
                }]}/>
                <div className="gmapbox">
                    <div id="googleMap" className="map">
                        <Map height={'450'}/>
                    </div>
                </div>
                <Banner
                    title={SiteData.contact.closingText()}
                    heading='Call Us Anytime'
                    phone={SiteData.phoneNumber}
                    bannerType='banner banner_bg_color'
                />
            </div>

        </>
    )
        ;
};

export default ContactPage;