import React from "react";
import {
  Box,
  Text,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
} from "@chakra-ui/react";

export const RoofingInsulation = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Spray Foam Roofing Services</Heading>
        <Text>
          In addition to our insulation services, Spray Foam Insulation
          Westminster offers spray foam roofing services for residential and
          commercial properties in Westminster, Colorado. Our spray foam roofing
          provides a seamless and durable surface that can withstand harsh
          weather conditions. Count on us to deliver roofing solutions that not
          only enhance the longevity of your property but also provide a
          reliable and weather-resistant barrier for both residential and
          commercial structures in Westminster.
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Quality Materials:</b> At Spray Foam Insulation Westminster, we
            prioritize high-quality spray foam in our roofing services to ensure
            that your roof can withstand harsh weather conditions. Our
            commitment to using top-tier materials guarantees a durable and
            resilient surface, providing reliable protection against the
            elements for both residential and commercial properties in
            Westminster, Colorado. Trust our expertise to deliver spray foam
            roofing solutions that contribute to the long-term durability and
            weather resistance of your property.
          </ListItem>
          <ListItem>
            <b>Experienced Technicians:</b> Count on our skilled team at Spray
            Foam Insulation Westminster to apply spray foam evenly and
            efficiently, ensuring optimal coverage for your roofing needs. With
            precision and expertise, we guarantee a uniform application that
            maximizes the effectiveness of the spray foam, providing a seamless
            and durable surface for both residential and commercial properties
            in Westminster, Colorado. Trust us to deliver roofing solutions that
            prioritize quality craftsmanship and long-lasting performance.
          </ListItem>
          <ListItem>
            <b>Energy Efficiency:</b> Spray foam roofing from Spray Foam
            Insulation Westminster makes a significant contribution to the
            energy efficiency of your property, aiding in the reduction of
            heating and cooling costs. By providing a seamless and insulating
            surface, our spray foam roofing effectively minimizes heat transfer,
            creating a more energy-efficient environment for both residential
            and commercial properties in Westminster, Colorado. Trust us to
            deliver roofing solutions that not only enhance durability but also
            contribute to long-term cost savings and improved energy efficiency.
          </ListItem>
          <ListItem>
            <b>Durable and Leak-resistant:</b> Opt for Spray Foam Insulation
            Westminster's spray foam roofing for a seamless and water-resistant
            barrier, ensuring superior protection for your property. Our roofing
            solutions create a durable surface that effectively repels water,
            guarding against potential damage and enhancing the longevity of
            both residential and commercial structures in Westminster, Colorado.
            Trust our expertise to deliver roofing solutions that prioritize
            resilience, providing a reliable shield against the elements for
            your property.
          </ListItem>
        </UnorderedList>
      </Stack>
    </Box>
  );
};
