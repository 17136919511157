import React from "react";
import {SiteData} from "../../Constants/siteData";
import {PageWrapper} from "../../Components/PageWrapper/PageWrapper";
import PageHeader from "../../Components/common/PageHeader";
import ServicePageTemplate from "../../Containers/ServiceContainers/ServicePageTemplate";
import {SEO} from "../../Components/SEO/SEO";

export default function EnergyAudits() {
    return (
        <PageWrapper>
            <SEO description={SiteData.energyAuditService.metaData.description}
                 title={SiteData.energyAuditService.metaData.title}/>
            <PageHeader header={SiteData.energyAuditService.h1} subtitle={SiteData.energyAuditService.subtitle}/>
            <ServicePageTemplate body={SiteData.energyAuditService.body}/>
        </PageWrapper>
    )
}
